@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

body {
	background:#9ba4da;
	background-image: url(../img/bg.jpg);
	background-position: top center;
	background-repeat: repeat;
}

.row {
	background: #fff;
}

#menu {
	display: none;
}

#header {
	position: relative;
	height: 320px;
}

#header #menu-img {
	position: absolute;
	left: 0;
	top: 140px;
	z-index: 15;
}

#header .logo-container {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
}


#header #slideshow-container {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 5;
	overflow:hidden;
	width: 687px;
	height: 220px;
}

#header #slideshow-container ul, 
#header #slideshow-container ul li {
	list-style:none;
	position:relative;
	margin:0;
	padding:0;
}

#header #slideshow-container li {
	overflow:hidden;
}

#header #lang-menu {
	position: absolute;
	right: 0;
	top: 0;
	background-image: url(../img/bg-language-noflags.png);
	background-position: top right;
	background-repeat: no-repeat;
	height: 116px;
	width: 220px;
	text-align: right;
	padding: 6px;
	z-index: 10;
}

#lang-menu-offset {
	margin-top: rem-calc(20);
	a {
		margin: rem-calc(20);
	}
}

.off-canvas a {
	color: $white;
}

ul.two-col {
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}

#content img {
	border: #92aade 10px solid;
}

#content img {
	border: #fff 10px solid;
	-moz-box-shadow: 3px 3px 10px #888;
	-webkit-box-shadow: 3px 3px 10px #888;
	box-shadow: 1px 1px 10px #888;
	margin: 5px 0 5px 0;
}

#content img.no-border {
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	border: 0;
}

.pm-block {
	border: 2px solid $primary-color;
	h2 {
		padding: 4px;
		background-color: $primary-color;
		text-align: center;
		a {
			color: $white;
		}
	}
	img.clean {
		padding: 0 !important;
		border: 20px solid $white !important;
		border-top: 5px solid $white !important;
		border-bottom: 5px solid $white !important;
		-moz-box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
		box-shadow: unset !important;
		margin: 0;
	}
	p {
		text-align: center;
		padding: 2px 20px;
	}
	.buttons {
		text-align: right;
		padding: 2px 20px;
	}
}

ul.subitems {

}


ul.subitems li {
	list-style: none;
}

ul.subitems li a {
	background-image: url(../img/camera-icon.png);
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 30px;
	text-decoration: none;
	display: block;
}

ul.subitems li a:hover {
	text-decoration: underline;
}

div.grid-masonry div.section {
	border: #9ba4da 10px solid;
	-moz-box-shadow: 3px 3px 10px #888;
	-webkit-box-shadow: 3px 3px 10px #888;
	box-shadow: 1px 1px 10px #888;
	margin: 10px;
	width: 280px;
	padding: 15px;
	padding-bottom: 0px;
}

.nixhier
{
	display: none !important;
}      
